@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");

* {
  font-family: Ubuntu, sans-serif;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: contain;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  * {
    z-index: 2;
  }

  canvas {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }
}
